.tem-portrait {
  width: 5em;
  height: 100%;
  margin: var(--m-h);
  border-radius: 10px;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: var(--color-tem-blue) 3px solid;
    border-radius: 10px;
  }
}