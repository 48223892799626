$badgeSize: 2em;

.tem-type-badge {
  width: $badgeSize;
  height: $badgeSize;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &-detailed {
    width: 6em;
    margin: var(--m-h);
    display: flex;
    align-items: center;
    font-size: 20px;
    border-radius: 10px;
    transition: opacity 225ms ease;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    img {
      width: $badgeSize;
      margin-right: var(--m-h);
    }
  }

}