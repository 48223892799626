.dashboard {
  font-family: "Temfont Regular";
  padding: var(--m);

  .dashboard-section {
    display: flex;
    width: 100%;
    max-height: 400px;

    > *:not(:first-child) {
      margin-left: var(--m);
    }
  }

  .tem-type-legend {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    user-select: none;
  }
}