:root {
  --color-tem-purple: #402b4b;
  --color-tem-yellow: #ffb457;
  --color-tem-blue: #1cd1d3;

  --color-type-neutral: #e6f7f7;
  --color-type-wind: #09fab3;
  --color-type-earth: #ba795c;
  --color-type-water: #45cbff;
  --color-type-fire: #e85c4e;
  --color-type-nature: #adde77;
  --color-type-electric: #ffe174;
  --color-type-mental: #c369a3;
  --color-type-digital: #a4c0c1;
  --color-type-melee: #fa9260;
  --color-type-crystal: #ea4a68;
  --color-type-toxic: #5c565c;

  --color-bg-500: var(--color-tem-purple);
  --color-bg-600: #2d2432;
  --color-bg-700: #120e14;
  --color-bg-800: #0d0b0e;
}
