@import './layout.scss';
@import './palette.scss';

.app {
  width: 100%;
  height: 100%;
  background-color: wheat;
  overflow: hidden;

  $headerHeight: 4em;

  .header { 
    width: 100%;
    height: $headerHeight;
    background-color: var(--color-tem-purple);
    position: absolute;
    top: 0;

    ul {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        height: 50%;
        padding: var(--m);
        list-style: none;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      a {
        text-decoration: none;
        color: white;
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - #{$headerHeight});
    position: absolute;
    top: $headerHeight;
    background-color: var(--color-bg-700);
  }
}