:root {
  --m: 1em;
  --m-h: calc(var(--m) / 2);
}

@font-face {
  font-family: "Temfont Regular";
  src: local("Temfont Regular"), url("../../public/fonts/Temfont-Regular.ttf") format("truetype");
}

// #root is the root React element, the id can be configured in index.tsx ReactDOM.createRoot()
html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}