.home {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;

  .controls {
    box-shadow: 2px 2px 5px var(--color-bg-800);
  }
}
