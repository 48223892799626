.tem-list {
  height: 100%;
  width: 20em;
  color: white;
  margin: 0;
  padding: 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color-bg-700);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-tem-blue);
    border-radius: 10px;
  }

  
  .tem-list-item {
    background-color: var(--color-bg-600);
    display: flex;
    cursor: pointer;
    transition: background-color 225ms ease;

    &:not(:last-child) {
      border-bottom: var(--color-bg-700) 1px solid;
    }

    &:hover {
      background-color: var(--color-bg-700);
    }

    .tem-info {
      height: 100%;
      padding: var(--m-h) 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .tem-name {
        font-size: 32px;
      }

      .tem-number {
        font-size: 20px;
      }
    }

    .tem-types {
      display: flex;
      padding-right: var(--m-h);
      justify-content: flex-end;
      align-items: center;
    }
  }
}