.tem-type-chart {
  user-select: none;

  .tem-type-wedge {
    cursor: pointer;
    transition: opacity 225ms ease;

    &:hover {
      opacity: 0.9;
    }
  }

  text {
    pointer-events: none;
  }
}